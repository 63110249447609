<template>
  <div class="min_h_100_vh bg_col_F5F7F9">
    <div>
      <!-- 背景 -->
      <img
        class="w_100_per dis_flex"
        src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220614/MZWvAgujPhUFqeYqKZbQ.png"
      />

      <!-- 内容 -->
      <div class="pos_abs l_0 t_0 w_100_per">
        <div class="m_0_16 m_t_24">
          <div class="font_24 col_fff">转账</div>
          <div class="font_14 col_fff opa_70_per m_t_8">bank transfer</div>
        </div>

        <!--  -->
      </div>

      <!--  -->
    </div>

    <!-- 收缩 -->
    <div class="m_b_neg_120"></div>



    <!-- 块 -->
    <div class="pos_rel bg_col_fff bor_rad_8 over_hid m_0_16 m_t_16">
      <!-- 项 -->
      <div class="dis_flex ali_it_cen bg_col_fff p_16">
        <div class="flex_1 font_14">账户简称</div>

        <div
          :class="`font_14 ${
            bankList.find((it) => it.value == form.transferBankId) ? '' : 'col_777'
          } m_l_16`"
          @click="form = { ...form, bankDialogShow: true }"
        >
          {{
            (bankList.find((it) => it.value == form.transferBankId) || {}).label ||
            '请选择'
          }}
        </div>

        <!--  -->
      </div>

      <!-- 选择 弹窗 -->
      <PickEnum
        :show.sync="form.bankDialogShow"
        :columns="bankList"
        @handleConfirm="
          (v) =>
            (form = {
              ...form,
              transferNickName: v.label,
              transferBankId: v.value,
            })
        "
      />

      <!-- 灰线 -->
      <div class="h_1 bg_col_F5F7F9"></div>

      <!-- 项 -->
      <div class="dis_flex ali_it_cen bg_col_fff p_16">
        <div class="font_14">转账金额</div>

        <input
          class="w_0 flex_1 font_14 text_ali_r bor_none m_l_16"
          v-model="form.amount"
          type="number"
          placeholder="请输入"
        />
        <!--  -->
      </div>


      <!-- 灰线 -->
      <div class="h_1 bg_col_F5F7F9"></div>
      <!-- 项 -->
      <div class="dis_flex ali_it_cen bg_col_fff p_16">
        <div class="flex_1 font_14">对方账户简称</div>

        <div
          :class="`font_14 ${
            bankList.find((it) => it.value == form.collectBankId) ? '' : 'col_777'
          } m_l_16`"
          @click="form = { ...form, bankDialogShow1: true }"
        >
          {{
            (bankList.find((it) => it.value == form.collectBankId) || {}).label ||
            '请选择'
          }}
        </div>

        <!--  -->
      </div>

      <!-- 选择 弹窗 -->
      <PickEnum
        :show.sync="form.bankDialogShow1"
        :columns="bankList"
        @handleConfirm="
          (v) =>
            (form = {
              ...form,
              collectNickName: v.label,
              collectBankId: v.value,
            })
        "
      />



      <!-- 灰线 -->
      <div class="h_1 bg_col_F5F7F9"></div>

      <!-- 项 -->
      <div class="dis_flex ali_it_cen bg_col_fff p_16">
        <div class="font_14">备注</div>

        <input
          class="w_0 flex_1 font_14 text_ali_r bor_none m_l_16"
          v-model="form.remark"
          type="text"
          placeholder="请输入"
        />
        <!--  -->
      </div>

      <!-- 灰线 -->
      <div class="h_1 bg_col_F5F7F9"></div>

      <!-- 块 -->
      <div class="pos_rel bg_col_fff bor_rad_8 over_hid p_16 m_0_16 m_t_neg_48">
        <!-- 标题 -->
        <div class="dis_flex ali_it_cen">
          <div class="flex_1 font_18 font_bold">上传凭证</div>
        </div>

        <!-- 上传身份证 -->
        <div class="dis_flex m_t_16">
          <div class="flex_1">
            <UploadFile
              class="h_24_vw"
              :src="
              form.voucherUrl
            "
              :isOnlyImg="true"
              :isReturnFile="true"
              @handleUpload="(file) => upload('front.png', file)"
            />

            <div class="dis_flex ju_con_cen font_14 col_666 m_t_8">
              点击上传凭证
            </div>

            <!--  -->
          </div>


          <!--  -->
        </div>

        <!--  -->
      </div>

      <!--  -->
    </div>


    <!-- 提交 -->
    <div class="dis_flex m_0_16 m_t_32">
      <van-button class="flex_1 font_16" type="info" @click="submit()"
        >提交</van-button
      >
    </div>

    <!-- 留白 -->
    <div class="h_56"></div>

    <!--  -->
  </div>
</template>

<script>
import { Toast } from 'vant';

import {
  brokerFinanceTransferRecordAdd,
  upload,
  brokerFinanceBankList,
} from '@/api/index';

export default {
  components: {
    UploadFile: () => import('@/components/UploadFile'),
    PickEnum: () => import('@/components/PickEnum'),
  },
  data() {
    return {
      form: {},

      bankList: [],

      //
    };
  },
  computed: {},
  mounted() {
    this.getBankList();


    //
  },
  methods: {
    // 提交
    async submit() {
      try {
        if (!this.form.transferBankId) {
          throw '请选择账户简称';
        }

        if (!this.form.amount) {
          throw '请输入正确的金额';
        }


        if (!this.form.collectBankId) {
          throw '请选择对方账户简称';
        }


        if (this.form.transferBankId==this.form.collectBankId) {
          throw '账户简称与对方账户简称不能相同';
        }


        Toast.loading({
          duration: 10 * 1000,
          message: '请稍后..',
          forbidClick: true,
        });

        await brokerFinanceTransferRecordAdd(this.form);

        Toast.success({
          message: '提交成功',
          forbidClick: true,
        });

        setTimeout(() => {
          // 2.3.0.2废弃
          // this.$router.go(-1);

          this.$router.replace('/transfer');

          //
        }, 1000);

        //
      } catch (err) {
        console.log(err)
        Toast(err);
      }

      //
    },

    // upload
    async upload(side, file) {
      let params = new FormData();
      params.append('name', side);
      params.append('file', file);

      try {
        Toast.loading({
          duration: 10 * 1000,
          message: '请稍后..',
          forbidClick: true,
        });

        const data = await upload(params);

        console.log(data);
        Toast.clear();

        let form = {
          ...this.form,
        };
        if (data) form.voucherUrl = data;



        this.form = form;

        //
      } catch (err) {
        Toast(err);
      }

      //
    },


    // 获取 银行
    async getBankList() {
      const data = await brokerFinanceBankList();

      this.bankList = data.map((item) => {
        return {
          ...item,
          label: item.nickName,
          value: item.bankId,
        };
      });

      //
    },

    //
  },
};
</script>
