<template>
  <div class="min_h_100_vh dis_flex flex_dir_col bg_col_F5F7F9">
    <!-- 标题 -->
    <div class="pos_rel">
      <!-- 背景 -->
      <img
        class="w_100_per dis_flex"
        src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220718/rxHbijhZUaEXzxchWqsM.png"
      />

      <!-- 内容 -->
      <div class="pos_abs l_0 t_0 w_100_per dis_flex flex_dir_col">
        <div class="dis_flex ali_it_cen m_0_16 m_t_24">
          <img
            class="w_24 h_24"
            src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220718/VRqZWyWdayMcReQlJlOt.png"
          />

          <div class="font_20 m_l_16">银行卡数据</div>

          <!--  -->
        </div>

        <!--  -->
      </div>

      <!--  -->
    </div>

    <!-- 收缩 -->
    <div class="m_t_neg_144"></div>

<DataList />
    <!-- 留白 -->
    <div class="h_32"></div>

    <!--  -->
  </div>
</template>

<script>
export default {
  components: {
   DataList: () => import('./components/BankList.vue'),
  },
  data() {
    return {
      loanGeneralStatistics: {},

      //
    };
  },
  computed: {},
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>
