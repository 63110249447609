var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"min_h_100_vh bg_col_F5F7F9"},[_vm._m(0),_c('div',{staticClass:"m_b_neg_120"}),_c('div',{staticClass:"pos_rel bg_col_fff bor_rad_8 over_hid m_0_16 m_t_16"},[_c('div',{staticClass:"dis_flex ali_it_cen bg_col_fff p_16"},[_c('div',{staticClass:"flex_1 font_14"},[_vm._v("账户简称")]),_c('div',{class:`font_14 ${
          _vm.bankList.find((it) => it.value == _vm.form.transferBankId) ? '' : 'col_777'
        } m_l_16`,on:{"click":function($event){_vm.form = { ..._vm.form, bankDialogShow: true }}}},[_vm._v(" "+_vm._s((_vm.bankList.find((it) => it.value == _vm.form.transferBankId) || {}).label || '请选择')+" ")])]),_c('PickEnum',{attrs:{"show":_vm.form.bankDialogShow,"columns":_vm.bankList},on:{"update:show":function($event){return _vm.$set(_vm.form, "bankDialogShow", $event)},"handleConfirm":(v) =>
          (_vm.form = {
            ..._vm.form,
            transferNickName: v.label,
            transferBankId: v.value,
          })}}),_c('div',{staticClass:"h_1 bg_col_F5F7F9"}),_c('div',{staticClass:"dis_flex ali_it_cen bg_col_fff p_16"},[_c('div',{staticClass:"font_14"},[_vm._v("转账金额")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.amount),expression:"form.amount"}],staticClass:"w_0 flex_1 font_14 text_ali_r bor_none m_l_16",attrs:{"type":"number","placeholder":"请输入"},domProps:{"value":(_vm.form.amount)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "amount", $event.target.value)}}})]),_c('div',{staticClass:"h_1 bg_col_F5F7F9"}),_c('div',{staticClass:"dis_flex ali_it_cen bg_col_fff p_16"},[_c('div',{staticClass:"flex_1 font_14"},[_vm._v("对方账户简称")]),_c('div',{class:`font_14 ${
          _vm.bankList.find((it) => it.value == _vm.form.collectBankId) ? '' : 'col_777'
        } m_l_16`,on:{"click":function($event){_vm.form = { ..._vm.form, bankDialogShow1: true }}}},[_vm._v(" "+_vm._s((_vm.bankList.find((it) => it.value == _vm.form.collectBankId) || {}).label || '请选择')+" ")])]),_c('PickEnum',{attrs:{"show":_vm.form.bankDialogShow1,"columns":_vm.bankList},on:{"update:show":function($event){return _vm.$set(_vm.form, "bankDialogShow1", $event)},"handleConfirm":(v) =>
          (_vm.form = {
            ..._vm.form,
            collectNickName: v.label,
            collectBankId: v.value,
          })}}),_c('div',{staticClass:"h_1 bg_col_F5F7F9"}),_c('div',{staticClass:"dis_flex ali_it_cen bg_col_fff p_16"},[_c('div',{staticClass:"font_14"},[_vm._v("备注")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.remark),expression:"form.remark"}],staticClass:"w_0 flex_1 font_14 text_ali_r bor_none m_l_16",attrs:{"type":"text","placeholder":"请输入"},domProps:{"value":(_vm.form.remark)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "remark", $event.target.value)}}})]),_c('div',{staticClass:"h_1 bg_col_F5F7F9"}),_c('div',{staticClass:"pos_rel bg_col_fff bor_rad_8 over_hid p_16 m_0_16 m_t_neg_48"},[_vm._m(1),_c('div',{staticClass:"dis_flex m_t_16"},[_c('div',{staticClass:"flex_1"},[_c('UploadFile',{staticClass:"h_24_vw",attrs:{"src":_vm.form.voucherUrl,"isOnlyImg":true,"isReturnFile":true},on:{"handleUpload":(file) => _vm.upload('front.png', file)}}),_c('div',{staticClass:"dis_flex ju_con_cen font_14 col_666 m_t_8"},[_vm._v(" 点击上传凭证 ")])],1)])])],1),_c('div',{staticClass:"dis_flex m_0_16 m_t_32"},[_c('van-button',{staticClass:"flex_1 font_16",attrs:{"type":"info"},on:{"click":function($event){return _vm.submit()}}},[_vm._v("提交")])],1),_c('div',{staticClass:"h_56"})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"w_100_per dis_flex",attrs:{"src":"http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220614/MZWvAgujPhUFqeYqKZbQ.png"}}),_c('div',{staticClass:"pos_abs l_0 t_0 w_100_per"},[_c('div',{staticClass:"m_0_16 m_t_24"},[_c('div',{staticClass:"font_24 col_fff"},[_vm._v("转账")]),_c('div',{staticClass:"font_14 col_fff opa_70_per m_t_8"},[_vm._v("bank transfer")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dis_flex ali_it_cen"},[_c('div',{staticClass:"flex_1 font_18 font_bold"},[_vm._v("上传凭证")])])
}]

export { render, staticRenderFns }