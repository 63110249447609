<template>
  <div class="h_100_vh dis_flex flex_dir_col">
    <div class="pos_rel">
      <!-- 背景 -->
      <img
        class="w_100_per dis_flex"
        src="http://songcw-sit.oss-cn-shanghai.aliyuncs.com/car-agent/20221020/ENlLJNLAeWaabMzrQPbn.svg"
      />

      <!-- 内容 -->
      <div class="pos_abs l_0 t_0 w_100_per h_100_per dis_flex flex_dir_col">
        <!-- 标题 -->
        <div class="dis_flex ju_con_cen ali_it_cen m_t_20 h_36 ">
          <div class="font_22 col_fff l_h_100_per ">转账记录</div>

        </div>

        <!-- 搜索 -->
        <div class="flex_1 m_t_12">
          <div class="dis_flex ali_it_cen bg_col_F5F7F9 bor_rad_4 p_8 m_0_12">
            <!-- 图标 -->
            <img
              class="w_16 h_16 dis_flex"
              src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220614/iOvIyoDBGVzEgDcQfPmk.png"
            />

            <input
              class="
                flex_1
                w_0
                font_16
                pla_col_C9C9C9
                bor_none
                bg_col_tra
                m_l_8
              "
              type="text"
              v-model="filterForm.transferNickName"
              placeholder="简称"
              maxlength="20"
            />

            <!-- 搜索 -->
            <div
              class="font_14 col_BC9D7E bor_rad_4 active_opa_75_per"
              @click="
                $refs['pullRefresh'].scrollTop = 0;
                getList({ pageNum: 1 });
              "
            >
              搜索
            </div>

            <!--  -->
          </div>

          <!--  -->
        </div>

        <!--  -->
      </div>

      <!-- 收缩 -->
      <div class="m_b_neg_12"></div>

      <!-- 标题 -->
      <div
        class="pos_rel dis_flex ali_it_cen bg_col_fff bor_rad_12_12_0_0 p_8_12"
      >
        <div class="flex_1 font_16 font_bold">转账列表</div>

        <div
          class="
            dis_flex
            ali_it_cen
            font_14
            l_h_100_per
            bg_col_F5F7F9
            bor_rad_4
            p_8_16
          "
          @click="transfer"
        >
          <div
            :class="`font_14 col_66BB6A`"
          >
            转账
          </div>
        </div>


        <!--  -->
      </div>

      <!--  -->
    </div>

    <!-- 上下拉 -->
    <div ref="pullRefresh" class="flex_1 over_y_auto h_1 bg_col_F5F7F9">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :immediate-check="false"
          :finished="finished"
          :finished-text="list.length ? '没有更多了' : null"
          @load="onLoad"
        >
          <!-- 项 -->
          <ItemOrder
            v-for="(item, index) in list"
            :key="index"
            :item="item"
            class="box_sha_0_2_4_0_rgba_0_39_62_80_per m_0_12 m_t_12"
            @handleDetail="
              () => {
                window.sessionStorage.orderFilterForm =
                  JSON.stringify(filterForm);
                window.sessionStorage.orderPageNum = pageNum;
                window.sessionStorage.orderScrollTop =
                  $refs['pullRefresh'].scrollTop;
              }
            "
          />

          <!-- 缺省 -->
          <NoData v-if="!list.length" style="height: calc(100vh - 60vw)" />

          <!--  -->
        </van-list>
      </van-pull-refresh>

      <!--  -->
    </div>

    <!-- 底部导航 -->
    <BottomTabBar />

    <!--  -->
  </div>
</template>

<script>
import { Toast } from 'vant';
import { brokerFinanceTransferRecord } from '@/api/index';

export default {
  components: {
    BottomTabBar: () => import('@/components/BottomTabBar.vue'),
    ItemOrder: () => import('./components/TransferList.vue'),
    NoData: () => import('@/components/NoData.vue'),
  },
  data() {
    return {
      userInfo: {},
      filterForm: {},

      /**
       *  状态 枚举
       */
      statusList: [],

      /**
       * 列表
       */
      list: [],
      pageNum: 1,
      pageSize: 10,

      /**
       * 上下拉刷新
       */
      refreshing: false,
      loading: false,
      finished: false,

      //
    };
  },
  computed: {},
  mounted() {
    if (localStorage.userInfo) {
      const u = JSON.parse(localStorage.userInfo);
      this.userInfo = u;
    }

    const query = this.$route.query;
    if (query.statusIn) {
      this.filterForm = {
        ...this.filterForm,
        statusIn: query.statusIn.split('|'),
      };
    } else {

      // 默认全选
      this.filterForm = {
        ...this.filterForm,
        statusIn: this.statusList.map((it) => it.key),
      };
    }

    // 恢复刚才浏览的搜索
    if (sessionStorage.orderFilterForm) {
      this.filterForm = JSON.parse(sessionStorage.orderFilterForm);
      sessionStorage.removeItem('orderFilterForm');
    }

    this.getList({
      pageNum: 1,
      pageSize: sessionStorage.orderPageNum
        ? +sessionStorage.orderPageNum * this.pageSize
        : undefined,
    }).then(() => {
      this.$nextTick(() => {
        // 恢复刚才浏览的位置
        if (sessionStorage.orderScrollTop) {
          this.$refs['pullRefresh'].scrollTop = sessionStorage.orderScrollTop;
          this.pageNum = +sessionStorage.orderPageNum;
          sessionStorage.removeItem('orderScrollTop');
          sessionStorage.removeItem('orderPageNum');
        }

        //
      });

      //
    });

    //
  },
  methods: {

    // 转账
    transfer() {
      this.$router.push(`/transferdo`);
    },

    // 上拉加载
    onLoad() {
      this.getList({ pageNum: parseInt(this.pageNum) + 1 });
    },

    // 下拉刷新
    onRefresh() {
      this.getList({ pageNum: 1 });
    },

    // 获取 列表
    async getList({ pageNum = 1, pageSize = this.pageSize }) {
      try {
        if (pageNum == 1) {
          this.$refs['pullRefresh'].scrollTop = 0;

          Toast.loading({
            duration: 10 * 1000,
            message: '请稍后..',
            forbidClick: true,
          });
        }

        const data = await brokerFinanceTransferRecord({
          current: pageNum,
          size: pageSize || this.pageSize,
          officialAccountKey: this.userInfo.officialAccountKey,
          ...this.filterForm,
        });
        Toast.clear();
        this.refreshing = this.loading = false;

        const len = data.records.length;

        this.list =
          data.current == 1 ? data.records : [...this.list, ...data.records];
        this.finished = len < pageSize;
        this.pageNum =
          this.list.length > 0
            ? Math.ceil(this.list.length / this.pageSize)
            : 1;

        //
      } catch (err) {
        Toast(err);
      }

      //
    },



    //

  },
};
</script>
