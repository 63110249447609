<template>
  <div class="min_h_100_vh dis_flex flex_dir_col bg_col_F5F7F9">
    <!-- 管理员信息 -->
    <div class="dis_flex ali_it_cen m_0_16 m_t_16">
      <div class="font_16">
        欢迎您，<span class="font_bold">{{ authUserInfo.nickName }}</span>
      </div>

      <div class="flex_1"></div>

      <div
        class="
          font_12
          col_fff
          l_h_100_per
          bor_rad_4
          bg_col_2B8EFF
          active_opa_75_per
          p_8_16
        "
        @click="logout()"
      >
        退出
      </div>

      <!--  -->
    </div>

    <!-- 经纪人分析 -->
    <div
      class="
        bg_col_fff
        bor_rad_4
        box_sha_0_0_8_8_black_80_per
        p_16
        m_0_16 m_t_16
      "
    >
      <div class="font_16">财务管理</div>

      <!-- 经纪人数据统计 -->
      <div
        class="
          dis_flex
          ali_it_cen
          bg_col_lin_270_E2E5F9_DCF2FF
          bor_rad_4
          m_t_16
        "
        @click="itemClick('银行卡管理')"
      >
        <div class="flex_1 m_l_16">
          <div class="font_16">银行卡管理</div>
          <div class="font_12 col_999 m_t_8">BANK MANANGE</div>
        </div>

        <img
          class="h_88 dis_flex"
          src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220714/VemUCGNDvhedSCeuyJUl.png"
        />

        <!--  -->
      </div>

      <div
        class="
          dis_flex
          ali_it_cen
          bg_col_lin_270_FFECCC_FEDCC1
          bor_rad_4
          m_t_16
        "
        @click="itemClick('转账记录')"
      >
        <div class="flex_1 m_l_16">
          <div class="font_16">转账记录</div>
          <div class="font_12 col_999 m_t_8">TRANSFER RECORD</div>
        </div>

        <img
          class="h_88 dis_flex"
          src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220714/eazymExtPBjbUfKQgeMa.png"
        />

        <!--  -->
      </div>

      <!--  -->
    </div>

    <!-- 财务 -->
    <div
      class="
        bg_col_fff
        bor_rad_4
        box_sha_0_0_8_8_black_80_per
        p_16
        m_0_16 m_t_16
      "
    >
      <div class="font_16">财务统计</div>

            <div
              class="
                dis_flex
                ali_it_cen
                bg_col_lin_270_FFECCC_FEDCC1
                bor_rad_4
                m_t_16
              "
              @click="itemClick('产品数据统计')"
            >
              <div class="flex_1 m_l_16">
                <div class="font_16">产品数据统计</div>
                <div class="font_12 col_999 m_t_8">PRODUCT STATISTICS</div>
              </div>

              <img
                class="h_88 dis_flex"
                src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220714/eazymExtPBjbUfKQgeMa.png"
              />

              <!--  -->
            </div>

              <div
                class="
                  dis_flex
                  ali_it_cen
                  bg_col_lin_270_E2E5F9_DCF2FF
                  bor_rad_4
                  m_t_16
                "
                @click="itemClick('银行卡数据统计')"
              >
                <div class="flex_1 m_l_16">
                  <div class="font_16">银行卡数据统计</div>
                  <div class="font_12 col_999 m_t_8">BANK STASTISTICS</div>
                </div>

                <img
                  class="h_88 dis_flex"
                  src="http://songcw-sit.oss-cn-shanghai.aliyuncs.com/car-agent/20220726/hDQOpNnwKwkqDMmLEZon.png"
                />

                <!--  -->
              </div>

            <div
              class="
                dis_flex
                ali_it_cen
                bg_col_lin_270_FFECCC_FEDCC1
                bor_rad_4
                m_t_16
              "
              @click="itemClick('转账记录数据统计')"
            >
              <div class="flex_1 m_l_16">
                <div class="font_16">转账记录数据统计</div>
                <div class="font_12 col_999 m_t_8">TRANSFER RECORD STATISTICS</div>
              </div>

              <img
                class="h_88 dis_flex"
                src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220714/eazymExtPBjbUfKQgeMa.png"
              />

              <!--  -->
            </div>
      <!--  -->
    </div>


<!--    &lt;!&ndash; 业务分析 &ndash;&gt;-->
<!--    <div-->
<!--      class="-->
<!--        bg_col_fff-->
<!--        bor_rad_4-->
<!--        box_sha_0_0_8_8_black_80_per-->
<!--        p_16-->
<!--        m_0_16 m_t_16-->
<!--      "-->
<!--    >-->
<!--      <div class="font_16">业务分析</div>-->

<!--      &lt;!&ndash; 进件数据统计 &ndash;&gt;-->
<!--      <div-->
<!--        class="-->
<!--          dis_flex-->
<!--          ali_it_cen-->
<!--          bg_col_lin_270_FFECCC_FEDCC1-->
<!--          bor_rad_4-->
<!--          m_t_16-->
<!--        "-->
<!--        @click="itemClick('进件数据统计')"-->
<!--      >-->
<!--        <div class="flex_1 m_l_16">-->
<!--          <div class="font_16">进件数据统计</div>-->
<!--          <div class="font_12 col_999 m_t_8">INCOMING DATA STATISTICS</div>-->
<!--        </div>-->

<!--        <img-->
<!--          class="h_88 dis_flex"-->
<!--          src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220714/eazymExtPBjbUfKQgeMa.png"-->
<!--        />-->

<!--        &lt;!&ndash;  &ndash;&gt;-->
<!--      </div>-->

<!--      &lt;!&ndash;  &ndash;&gt;-->
<!--    </div>-->

<!--    &lt;!&ndash; 公众号分析 &ndash;&gt;-->
<!--    <div-->
<!--      class="-->
<!--        bg_col_fff-->
<!--        bor_rad_4-->
<!--        box_sha_0_0_8_8_black_80_per-->
<!--        p_16-->
<!--        m_0_16 m_t_16-->
<!--      "-->
<!--    >-->
<!--      <div class="font_16">公众号分析</div>-->

<!--      &lt;!&ndash; 公众号数据统计 &ndash;&gt;-->
<!--      <div-->
<!--        class="-->
<!--          dis_flex-->
<!--          ali_it_cen-->
<!--          bg_col_lin_270_D2E6FF_CAFBF2-->
<!--          bor_rad_4-->
<!--          m_t_16-->
<!--        "-->
<!--        @click="itemClick('公众号数据统计')"-->
<!--      >-->
<!--        <div class="flex_1 m_l_16">-->
<!--          <div class="font_16">公众号数据统计</div>-->
<!--          <div class="font_12 col_999 m_t_8">-->
<!--            WECHAT OFFICIAL ACCOUNT DATA STATISTICS-->
<!--          </div>-->
<!--        </div>-->

<!--        <img-->
<!--          class="h_88 dis_flex"-->
<!--          src="http://songcw-sit.oss-cn-shanghai.aliyuncs.com/car-agent/20220726/hDQOpNnwKwkqDMmLEZon.png"-->
<!--        />-->

<!--        &lt;!&ndash;  &ndash;&gt;-->
<!--      </div>-->

<!--      &lt;!&ndash;  &ndash;&gt;-->
<!--    </div>-->

    <!-- 留白 -->
    <div class="h_32"></div>

    <!--  -->
  </div>
</template>

<script>
import { Toast } from 'vant';

import { authRevokeDelete, authUser } from '@/api/index';

export default {
  components: {},
  data() {
    return {
      userInfo: {},
      authUserInfo: {},

      //
    };
  },
  computed: {},
  mounted() {
    if (localStorage.userInfo) {
      const u = JSON.parse(localStorage.userInfo);
      this.userInfo = u;
    }

    this.getAuthUserInfo();

    //
  },
  methods: {
    // 项 点击
    itemClick(name) {
      switch (name) {
        case '转账记录':
          this.$router.push('/transfer');
          break;

        case '银行卡管理':
          this.$router.push('/bankList');
          break;

        case '产品数据统计':
          this.$router.push('/statistics/product');
          break;
        case '银行卡数据统计':
          this.$router.push('/statistics/bank');
          break;

        case '转账记录数据统计':
          this.$router.push('/statistics/transfer');
          break;
      }

      //
    },

    // 登出
    logout() {
      this.$dialog
        .confirm({
          title: '提示',
          message: '您是否确认登出？',
        })
        .then(async () => {
          try {
            Toast.loading({
              duration: 10 * 1000,
              message: '请稍后..',
              forbidClick: true,
            });

            await authRevokeDelete(this.userInfo.access_token);

            Toast.clear();

            localStorage.removeItem('userInfo');
            this.$router.replace('/login');

            //
          } catch (err) {
            Toast(err);
          }

          //
        })
        .catch(() => {});

      //
    },

    // 获取 管理员信息
    async getAuthUserInfo() {
      this.authUserInfo = await authUser();
    },

    //
  },
};
</script>
