<template>
  <div class="min_h_100_vh bg_col_F5F7F9">
    <div>
      <!-- 背景 -->
      <img
        class="w_100_per dis_flex"
        src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220614/MZWvAgujPhUFqeYqKZbQ.png"
      />

      <!-- 内容 -->
      <div class="pos_abs l_0 t_0 w_100_per">
        <div class="m_0_16 m_t_24">
          <div class="font_24 col_fff">添加银行卡</div>
          <div class="font_14 col_fff opa_70_per m_t_8">add bank</div>
        </div>

        <!--  -->
      </div>

      <!--  -->
    </div>

    <!-- 收缩 -->
    <div class="m_b_neg_120"></div>



    <!-- 块 -->
    <div class="pos_rel bg_col_fff bor_rad_8 over_hid m_0_16 m_t_16">
      <!-- 项 -->
      <div class="dis_flex ali_it_cen bg_col_fff p_16">
        <div class="flex_1 font_14">账户简称</div>

        <input
          class="w_0 flex_1 font_14 text_ali_r bor_none m_l_16"
          v-model="form.nickName"
          type="text"
          placeholder="请输入"
        />

        <!--  -->
      </div>

      <!-- 灰线 -->
      <div class="h_1 bg_col_F5F7F9"></div>
      <!-- 项 -->
      <div class="dis_flex ali_it_cen bg_col_fff p_16">
        <div class="flex_1 font_14">开户行</div>

        <input
          class="w_0 flex_1 font_14 text_ali_r bor_none m_l_16"
          v-model="form.bankName"
          type="text"
          placeholder="请输入"
        />

        <!--  -->
      </div>


      <!-- 灰线 -->
      <div class="h_1 bg_col_F5F7F9"></div>

      <!-- 项 -->
      <div class="dis_flex ali_it_cen bg_col_fff p_16">
        <div class="font_14">银行账户</div>

        <input
          class="w_0 flex_1 font_14 text_ali_r bor_none m_l_16"
          v-model="form.bankNo"
          type="number"
          placeholder="请输入"
        />
        <!--  -->
      </div>


      <!-- 灰线 -->
      <div class="h_1 bg_col_F5F7F9"></div>

      <!-- 项 -->
      <div class="dis_flex ali_it_cen bg_col_fff p_16">
        <div class="font_14">余额</div>

        <input
          class="w_0 flex_1 font_14 text_ali_r bor_none m_l_16"
          v-model="form.balance"
          type="number"
          placeholder="请输入"
        />
        <!--  -->
      </div>


      <!-- 灰线 -->
      <div class="h_1 bg_col_F5F7F9"></div>
      <!-- 项 -->
      <div class="dis_flex ali_it_cen bg_col_fff p_16">
        <div class="flex_1 font_14">项目名称</div>

        <div
          :class="`font_14 ${
            productList.find((it) => it.value == form.productId) ? '' : 'col_777'
          } m_l_16`"
          @click="form = { ...form, bankDialogShow1: true }"
        >
          {{
            (productList.find((it) => it.value == form.productId) || {}).label ||
            '请选择'
          }}
        </div>

        <!--  -->
      </div>

      <!-- 选择 弹窗 -->
      <PickEnum
        :show.sync="form.bankDialogShow1"
        :columns="productList"
        @handleConfirm="
          (v) =>
            (form = {
              ...form,
              productName: v.label,
              productId: v.value,
            })
        "
      />

      <!-- 灰线 -->
      <div class="h_1 bg_col_F5F7F9"></div>
      <!-- 项 -->
      <div class="dis_flex ali_it_cen bg_col_fff p_16">
        <div class="flex_1 font_14">是否公司账户</div>

        <div
          :class="`font_14 ${
            typeList.find((it) => it.value == form.type) ? '' : 'col_777'
          } m_l_16`"
          @click="form = { ...form, bankDialogShow: true }"
        >
          {{
            (typeList.find((it) => it.value == form.type) || {}).label ||
            '请选择'
          }}
        </div>

        <!--  -->
      </div>

      <!-- 选择 弹窗 -->
      <PickEnum
        :show.sync="form.bankDialogShow"
        :columns="typeList"
        @handleConfirm="
          (v) =>
            (form = {
              ...form,
              type: v.value,
            })
        "
      />

      <!-- 灰线 -->
      <div class="h_1 bg_col_F5F7F9"></div>

      <!-- 项 -->
      <div class="dis_flex ali_it_cen bg_col_fff p_16">
        <div class="font_14">公司名称</div>

        <input
          class="w_0 flex_1 font_14 text_ali_r bor_none m_l_16"
          v-model="form.company"
          type="text"
          placeholder="请输入"
        />
        <!--  -->
      </div>

      <!-- 灰线 -->
      <div class="h_1 bg_col_F5F7F9"></div>

      <!-- 项 -->
      <div class="dis_flex ali_it_cen bg_col_fff p_16">
        <div class="font_14">备注</div>

        <input
          class="w_0 flex_1 font_14 text_ali_r bor_none m_l_16"
          v-model="form.remark"
          type="text"
          placeholder="请输入"
        />
        <!--  -->
      </div>
      <!--  -->
    </div>


    <!-- 提交 -->
    <div class="dis_flex m_0_16 m_t_32">
      <van-button class="flex_1 font_16" type="info" @click="submit()"
        >提交</van-button
      >
    </div>

    <!-- 留白 -->
    <div class="h_56"></div>

    <!--  -->
  </div>
</template>

<script>
import { Toast } from 'vant';

import {
  brokerFinanceBankAdd,
  brokerFinanceProductList
} from '@/api/index';

export default {
  components: {
    PickEnum: () => import('@/components/PickEnum'),
  },
  data() {
    return {
      form: {},

      productList: [],
      typeList: [{
        value:"Y",
        label:"公司账户"
      },{
      value:"N",
        label:"非公司账户"
    }]

      //
    };
  },
  computed: {},
  mounted() {
    this.getProductList();


    //
  },
  methods: {
    // 提交
    async submit() {
      try {
        if (!this.form.nickName) {
          throw '请输入账户简称';
        }

        if (!this.form.balance) {
          throw '请输入正确的金额';
        }

        if (!this.form.bankName) {
          throw '请输入开户行';
        }

        if (!this.form.bankNo) {
          throw '请输入银行账户';
        }

        if (!this.form.productId) {
          throw '请选择产品名称';
        }
        if (!this.form.type) {
          throw '请选择是否为公司账户';
        }


        Toast.loading({
          duration: 10 * 1000,
          message: '请稍后..',
          forbidClick: true,
        });

        await brokerFinanceBankAdd(this.form);

        Toast.success({
          message: '提交成功',
          forbidClick: true,
        });

        setTimeout(() => {
          // 2.3.0.2废弃
          // this.$router.go(-1);

          this.$router.replace('/bankList');

          //
        }, 1000);

        //
      } catch (err) {
        console.log(err)
        Toast(err);
      }

      //
    },



    // 获取
    async getProductList() {
      const data = await brokerFinanceProductList();

      this.productList = data.map((item) => {
        return {
          ...item,
          label: item.productName,
          value: item.productId,
        };
      });

      //
    },

    //
  },
};
</script>
