import * as http from '@/utils/http';
import { getRandom } from '@/utils/tools';


export const brokerFinanceTransferRecord = async (data) => {
  const res = await http.post(`api/manage/finance/transfer/record/page`, data);
  return res?.data || [];
};

export const brokerFinanceBankStatistics = async (data) => {
  const res = await http.post(`api/manage/finance/transfer/record/bank/statistics`, data);
  return res?.data || [];
};

export const brokerFinanceTransferRecordStatistics = async (data) => {
  const res = await http.post(`api/manage/finance/transfer/record/bank/todayStatistics`, data);
  return res?.data || [];
};

export const brokerFinanceProductStatistics = async (data) => {
  const res = await http.post(`api/manage/finance/transfer/record/product/statistics`, data);
  return res?.data || [];
};



export const brokerFinanceBank = async (data) => {
  const res = await http.post(`api/manage/finance/bank/page`, data);
  return res?.data || [];
};

export const brokerFinanceTransferRecordAdd = async (data) => {
  const res = await http.post(`api/manage/finance/transfer/record`, data);
  return res?.data || [];
};

export const brokerFinanceBankAdd = async (data) => {
  const res = await http.post(`api/manage/finance/bank`, data);
  return res?.data || [];
};

export const brokerFinanceProductList = async (data) => {
  const res = await http.post(`api/manage/finance/product/list`, data);
  return res?.data || [];
};

export const brokerFinanceBankList = async (data) => {
  const res = await http.post(`api/manage/finance/bank/list`, data);
  return res?.data || [];
};


export const upload = async (data) => {
  const res = await http.post(`api/upload`, data);
  return res?.data || [];
};

// 公众号统计走势图
export const brokerDashboardDataUserAnalysisTrendChartPost = async (data) => {
  const res = await http.post(`/api/manage/national/broker/dashboard/data/user/analysis/trend/chart`, data);
  return res?.data || [];
};

// 公众号统计
export const brokerDashboardDataUserAnalysisPost = async (data) => {
  const res = await http.post(`/api/manage/national/broker/dashboard/data/user/analysis`, data);
  return res?.data || {};
};

// 订单状态统计图
export const brokerDashboardOrderTrendChartStatisticsPost = async (data) => {
  const res = await http.post(`/api/manage/national/broker/dashboard/order/trend/chart/statistics`, data);
  return res?.data || [];
};

// App省长列表
export const brokerDashboardAppRegisterGovernorListPost = async (data) => {
  const res = await http.post(`/api/manage/national/broker/dashboard/app/register/governor/list`, data);
  return res?.data || [];
};

// App省长注册占比
export const brokerDashboardAppRegisterGovernorProportionPost = async (data) => {
  const res = await http.post(`/api/manage/national/broker/dashboard/app/register/governor/proportion`, data);
  return res?.data || [];
};

// App经纪人注册走势
export const brokerDashboardAppRegisterTrendChartPost = async (data) => {
  const res = await http.post(`/api/manage/national/broker/dashboard/app/register/trend/chart`, data);
  return res?.data || [];
};

// App经纪人-合伙人注册统计
export const brokerDashboardAppRegisterStatistics = async (data) => {
  const res = await http.get(`/api/manage/national/broker/dashboard/app/register/statistics`, data);
  return res?.data || {};
};

// 产品列表
export const brokerProductList = async () => {
  const res = await http.get(`/api/manage/national/broker/product/list`);
  return res.data;
};

// 获取当前登陆人管理省长信息
export const brokerDashboardGovernorList = async (data) => {
  const res = await http.get(`/api/manage/national/broker/dashboard/governor/list`, data);
  return res?.data || [];
};

// 进件概况统计
export const brokerDashboardLoanGeneralStatisticsPost = async (data) => {
  const res = await http.post(`/api/manage/national/broker/dashboard/app/loan/general/statistics?tsp=${new Date().getTime()}${getRandom({ max: 9999 })}`, data);
  return res?.data || {};
};

// 进件走势图
export const brokerDashboardTrendChartPost = async (data) => {
  const res = await http.post(`/api/manage/national/broker/dashboard/trend/chart`, data);
  return res?.data || {};
};

// 进件转化
export const brokerDashboardLoanConversionStatisticsPost = async (data) => {
  const res = await http.post(`/api/manage/national/broker/dashboard/loan/conversion/statistics`, data);
  return res?.data || {};
};

// 省长-城市合伙人统计
export const brokerDashboardStatisticsPost = async (data) => {
  const res = await http.post(`/api/manage/national/broker/dashboard/statistics`, data);
  return res?.data || [];
};

// 注册
export const commonNationalBrokerRegisterPost = async (data) => {
  const res = await http.post(`/common/national/broker/register`, data);
  return res.data;
};

// 根据手机号码发送验证码
export const commonNationalBrokerSmsPost = async (data) => {
  const res = await http.post(`/common/national/broker/sms`, data);
  return res.data;
};

// 登出
export const authRevokeDelete = async (token) => {
  const res = await http.del(`/auth/revoke`
    + `?token=${token}`);
  return res?.data;
};

// 管理员信息
export const authUser = async () => {
  const res = await http.post(`/api/manage/auth/user`);
  return res?.data || {};
};

// 登录
export const oauthTokenPost = async ({
  username, password
}) => {
  const data = await http.post(`/oauth/token`
    + `?client_id=voopin.manage&client_secret=iUD4dCYxn6AetqRJ2v&grant_type=password`
    + `&username=${username}&password=${password}`);
  return data;
};

// 文件上传
export const loanIncomingPartsUploadPost = async (data) => {
  const res = await http.post(`/loan/incoming/parts/upload`, data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  });
  return res.data;
};
